<template>
  <div class="home bg-dark-darker">
    <img alt="Vue logo" src="../assets/logo.png" />
    <h1 class="bg-primary"></h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  setup() {
    return {};
  },
};
</script>
